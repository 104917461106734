$black: #000;
$blue: #1565c0;
$dark-gray: #333;
$darkish-gray: rgba(0, 0, 0, 0.8);
$medium-gray: #999;
$light-gray: #ddd;
$white: #fff;

$hot-pink: #c2046c;
$plum: #73004c;
$light-grape: #75016a;
$grape: #5c015a;
$orange: #f25b35;
