.index {
  header {
    height: 13.5rem;
    text-align: center;
    width: 100%;

    @include grid-media($narrow-screen) {
      height: 10.5rem; }

    h1 {
      font-size: $font-XXL;
      left: 50%;
      letter-spacing: 3px;
      margin: 0;
      position: absolute;
      text-transform: uppercase;
      top: 118px;
      transform: translateX(-50%);
      white-space: nowrap;

      @include grid-media($narrow-screen) {
        font-size: $font-L;
        letter-spacing: 1.5px;
        top: 98px; }

      @include grid-media($extra-narrow-screen) {
        letter-spacing: -0.2px; } }
    h2 {
      color: darken($medium-gray, 12%);
      font-size: $font-XS;
      font-weight: 300;
      left: 50%;
      letter-spacing: 0.04rem;
      position: absolute;
      top: 206px;
      transform: translateX(-50%);
      white-space: nowrap;

      @include grid-media($narrow-screen) {
        font-size: $font-XXS;
        letter-spacing: 0.02rem;
        top: 146px; } } } }
