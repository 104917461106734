.brand_logo {
  background-image: url(/assets/images/logo-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
  padding: 0;
  text-indent: -100em;
  overflow: hidden; }


.brand_logo--small {
  width: 44px;
  height: 44px; }


.brand_logo--black {
  background-image: url(/assets/images/logo-black.svg); }



