html {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size / 16 * 100%;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: modular-scale(1);
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

h2 {
  font-weight: 200
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: $action-color;
  text-decoration-skip: ink;
  transition: color $base-duration $base-timing;

  &:hover {
    color: shade($action-color, 25%);
  }

  &:focus {
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}
