.fullscreenModal {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%; // temp solution
  height: 100%;
  color: $white;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  background: transparent;
  transition-delay: 0.5s;
  transition: opacity 0.3s ease-in;
  z-index: 800; }

.fullscreenModal.active {
  display: table !important;
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s; }

.fullscreenModal h2 {
  font-size: $font-M; }

.share_container {
  display: table-cell !important;
  vertical-align: middle !important; }

.modal-toggle-group {
  position: fixed;
  display: block;
  bottom: 30px;
  right: 3%;
  opacity: 0;
  visibility: hidden;
  transition: all .5s cubic-bezier(0.23, 1, 0.32, 1); }

.modal-toggle-group.active {
  opacity: 1;
  visibility: visible;

  &.scaleIn {
    transform: scale(1); } }

.modal-toggle-group,
.modal-toggle-wrapper,
.modal-toggle-close-wrapper,
.modal-toggle-bubble,
.modal-toggle-bubbleShadow {
  height: 44px;
  width: 44px;
  border-radius: 50%; }

.modal-toggle-wrapper {
  position: absolute;
  top: 0;
  display: block;
  background-image: url(/assets/images/heart.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60%;
  background-color: transparent;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.3s ease-out;
  z-index: 700; }

.modal-toggle-wrapper.active {
  opacity: 0;
  transform: rotate(360deg); }

.modal-toggle-close-wrapper {
  position: absolute;
  top: 0;
  z-index: 600;
  opacity: 0;
  transition: opacity 0.3s;

  .modal-toggle-close {
    position: absolute;
    top: 48%;
    left: 15%;
    display: block;
    width: 30px;
    height: 2px;
    background: transparent;
    transition: background 0.3s;
    z-index: inherit;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      background: inherit;
      width: 100%;
      height: 100%;
      transform: translateZ(0);
      backface-visibility: hidden;
      transition: transform 0.5s, background 0s; }

    &:before {
      top: -6px;
      transform: rotate(0); }

    &:after {
      bottom: -6px;
      transform: rotate(0); } } }

.modal-toggle-close-wrapper.active {
  opacity: 1;
  z-index: 600;

  .modal-toggle-close {
    background: transparent;

    &:before,
    &:after {
      background: $white; }

    &:before {
      top: 0;
      transform: rotate(135deg); }

    &:after {
      bottom: 0;
      transform: rotate(225deg); } } }

.modal-toggle-bubble {
  position: absolute;
  top: 0;
  background-color: rgba($black,.9);
  -webkit-transform: scale(1);
  -webkit-transition: all 0.3s ease-out;
  z-index: 600; }

.modal-toggle-bubble.active {
  -webkit-transform: scale(100);
  -webkit-transition: all 0.6s ease-out;
  -webkit-transition-delay: .4s; }

.modal-toggle-bubbleShadow {
  position: absolute;
  top: 0;
  background-color: $black;
  -webkit-transform: scale(1);
  opacity: 1;
  z-index: 500; }

.modal-toggle-bubbleShadow.active {
  -webkit-transform: scale(1.5);
  opacity: 0;
  -webkit-transition: all 0.3s ease-out; }

// share buttons

.h2--shareTitle {
  margin-top: -100px; }

.shareWrapper {
  @include clearfix();
  list-style: none;
  margin: $leading auto 0;
  padding: 0;
  width: 60%;

  li .shareButton {
    display: block;
    float: left;
    margin: 0;
    padding: 0 15px;
    width: 16.66%;
    height: 80px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    opacity: 0.8;

    &:hover {
      opacity: 1; }

    @media (min-width: $screen-sm-max) and (max-width: $screen-lg-min) {
      width: 16.66%;
      height: 60px; }

    @media (max-width: $screen-xs-max) {
      float: none;
      width: 100%;
      height: 40px;
      margin-bottom: 20px; } } }

.shareButton--twitter {
  background-image: url(/assets/images/twitter.svg); }

.shareButton--facebook {
  background-image: url(/assets/images/facebook.svg); }

.shareButton--linkedin {
  background-image: url(/assets/images/linkedin.svg); }

.shareButton--buffer {
  background-image: url(/assets/images/buffer.svg); }

.shareButton--hackernews {
  background-image: url(/assets/images/ycombinator.svg); }

.shareButton--pocket {
  background-image: url(/assets/images/pocket.svg); }
