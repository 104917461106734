#{$all-buttons} {
  background-color: #ffa70d;
  width: 250px;

  &:hover {
    background-color: darken(#ffa70d, 10%);
    color: contrast-switch(darken(#ffa70d, 10%));
  }
}

.section--footer {
  .copyright {
    font-family: $font-stack-helvetica;
  }
}

.formkit-overlay {
  background: rgba(0, 0, 0, 0.6) !important;
}

.hero-background {
  $base-border-radius: 3px !default;
  $action-color: $orange;
  $large-screen: (
    media: "screen and (min-width: 53.75em)"
  );
  $hero-padding-vertical: 5em;
  $hero-padding-horizontal: 0.4em;
  $hero-screen-width: 14em;
  $hero-screen-height: 25em;
  $hero-background: darken(gray, 40%);
  $hero-backside-background: darken($hero-background, 10%);
  $hero-text-color: white;
  $hero-background-top: rgba(0, 0, 0, 0.6);
  $hero-background-bottom: rgba(0, 0, 0, 0.8);
  $gradient-angle: 10deg;
  $hero-image: "/assets/images/chuttersnap-kyCNGGKCvyw-unsplash.jpg";

  @include grid-container;

  background: linear-gradient($gradient-angle, $hero-background-bottom, $hero-background-top), url($hero-image), no-repeat scroll;
  background-repeat: no-repeat;
  background-size: cover;
  color: $hero-text-color;
  min-height: 30em;
  padding-top: 150px;
  text-align: center;
  width: 100%;

  #waiting-list-form {
    margin: auto;
    padding-top: 2em;
  }


  .hero-text {
    background: rgba(11,31,49,0.8);
    margin: auto;
    max-width: $content-narrow;
    padding: 2em;

    h1 {
      font-size: 2em;
    }

    h2 {
      font-size: 1.5em;
    }

    p {
      font-size: 0.8em;
      line-height: 1.5em;
      margin-top: 1em;
      margin-bottom: 2em;

      a {
        color: $hero-text-color;
        text-decoration: underline;
      }

      strong {
        font-size: 1.1em;
        font-weight: 800;
      }
    }
  }
}

.modal {
  -ms-flex-line-pack: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  align-content: center;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: visible;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}


