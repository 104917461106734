section.subscribe {
  width: 100%;
  background: $white;
  margin: auto;
  padding: $leading 0;
  position: relative;

  .container {
    margin: auto;
    width: $content-narrow;

    @include grid-media($narrow-screen) {
      width: 100%; } } }
