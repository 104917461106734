.subscribe-success {
  header {
    height: 13.5rem;
    padding: 4rem 0;
    text-align: center;
    width: 100%; }

  .back {
    text-align: center;

    a {
      color: $dark-gray;
      text-decoration: none; } } }
