.index {
  .posts {
    background: #f2f2f2;
    clear: both;
    padding-top: 0;
    position: relative;
    z-index: 400;
    width: 100%;

    ul {
      margin: auto;
      margin-top: 3*$leading;
      width: $content-wide;

      @include grid-media($medium-screen) {
        width: $content-medium; }

      @include grid-media($narrow-screen) {
        width: 100%; }

      @include grid-container;

      li {
        margin-bottom: $leading;

        @include grid-column(3);

        @include grid-media($extra-narrow-screen) {
          @include grid-column(6); }

        &:nth-child(1) {
          @include grid-column(12);

          article {
            margin-top: -3*$leading; }

          .post_image {
            padding-top: 30%;

            @include grid-media($narrow-screen) {
              padding-top: 50%; } }

          .post_title {
            font-size: $font-S; } }

        &:nth-child(2), &:nth-child(3) {
          @include grid-column(6);

          .post_image {
            padding-top: 50%; }

          .post_title {
            font-size: $font-S; }

          @include grid-media($medium-screen) {
            @include grid-column(12); } }

        article {
          background: $white;
          box-shadow: 0 1px 4px rgba(0,0,0,.2);
          position: relative;
          padding-bottom: 2*$leading;
          &:hover {
            transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
            box-shadow: 0 4px 10px 1px rgba(0,0,0,.2); }

          a {
            color: $black;
            background: transparent;
            text-decoration: none; }

          .post_image {
            background-size: cover;
            background-position: center;
            padding-top: 100%;
            transition-property: all, left, top, width;
            transition-duration: .5s, .1s, .1s, .1s;
            transition-timing-function: cubic-bezier(.33,0,.2,1);

            @include grid-media($narrow-screen) {
              padding-top: 50%; } }

          .post_title {
            display: block;
            margin-top: 0.5*$leading;
            font-size: 0.75rem;
            font-weight: 300; }

          .post_title,
          .post_meta {
            padding-left: 15px;
            padding-right: 15px; }

          .post_meta {
            position: absolute;
            bottom: 0;
            padding-bottom: 10px;
            color: $light-gray;
            font-size: $font-XS;
            text-transform: uppercase; } } } } } }


.desaturate {
  opacity: 0.8;
  filter: gray;
  filter: contrast(1.5) url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");

  &:hover {
    filter: none; } }

