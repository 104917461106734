form.formkit-form {
  background: $white;
  border: 0px;

  h1.formkit-header {
    color: $black;
    font-family: $title-secondary-font;
    font-size: $font-L;
    margin: auto;
    margin-top: $leading;
    text-align: center; } }
